import { Modal } from "flowbite-react";
import { useEffect, useRef, useState } from 'react';
import Button from "../../../../components/button/button";
import TextBox from "../../../../components/forms/text-box";
import Message from "../../../../components/toast-message";
import SelectInput from "../../../../components/forms/select";
import { HiOutlineCloudUpload } from 'react-icons/hi';
import { HiOutlineDocument } from "react-icons/hi2";

import { useAppInfo } from "../../../../helpers/hooks/common-hook";
import {
  dataSourceCreate,
  fileUpload,
  uploadUrlGet
} from "../../../../services/knowledge/ds-connector/file-connector";
import { isValidURL, validateActionName } from "../../../../helpers/utils/text-util";

const initErrorObj = {
  dataSourceId: false,
  file: false,
  url: false,
  dbUrl: false,
  dbName: false,
  dbUser: false,
  dbPassword: false,
};

//
// 1) Sub-component for file-related fields
//
function FileFields({ fileName, handleSelectFile, error }) {
  return (
      <div>
        <label className="block text-sm font-medium text-gray-900 dark:text-white mb-2">
          File(s)
        </label>
        <div className="relative">
          <label
              className="flex gap-2 h-10 w-full rounded-lg border items-center cursor-pointer bg-gray-50 dark:bg-gray-70 text-sm border-gray-300  justify-center"
              htmlFor="fileContainer"
          >
          <span
              className={`text-[24px] ${fileName ? 'text-gray-800' : 'text-gray-400'}`}
          >
            {fileName ? <HiOutlineDocument /> : <HiOutlineCloudUpload />}
          </span>
            <span className={fileName ? 'text-gray-800' : 'text-gray-500'}>
            {fileName ? fileName : 'Drop files here or browse'}
          </span>
          </label>
          <input
              className="opacity-0 w-full absolute z-10 h-full top-0 left-0 cursor-pointer"
              id="fileContainer"
              type="file"
              accept=".pdf, .doc, .docx, .csv, .txt"
              onChange={handleSelectFile}
          />
        </div>
        <div className="text-sm text-gray-800 pt-2">
          Files supported .pdf, .doc, .docx, .txt, .csv
        </div>
        {error.file && (
            <p className="text-sm text-red-600 dark:text-red-500">
              <span className="font-medium">Please select a file</span>
            </p>
        )}
      </div>
  );
}

//
// 2) Sub-component for database-related fields
//
function DatabaseFields({ formData, handleChange, error }) {
  return (
      <div className="mt-4 space-y-3">

        <SelectInput
            label="Database Type"
            id="dbType"
            name="dbType"
            autoComplete="dbType"
            value={formData?.metadata?.dbType}
            onChange={handleChange}
            error={error.dbType}
            optionsComp={
              <>
                <option value="">Select Type</option>
                <option value="postgresql">PostgreSQL</option>
                <option value="mysql">MySQL</option>
              </>
            }
        />

        <div className="flex flex-row gap-2">
          <TextBox
              rootClass="w-3/4"
              label="Host"
              type="text"
              name="dbHost"
              id="dbHost"
              value={formData?.metadata?.dbHost || ""}
              placeholder="Host Address"
              onChange={handleChange}
              error={error.dbHost}
              errorMessage="Invalid Host"
          />
          <TextBox
              label="Port"
              type="number"
              name="dbPort"
              id="dbPort"
              value={formData?.metadata?.dbPort || ""}
              placeholder="Port"
              onChange={handleChange}
              error={error.dbPort}
              errorMessage="Invalid Port"
          />
        </div>

        <TextBox
            label="Database Name"
            type="text"
            name="dbName"
            id="dbName"
            value={formData?.metadata?.dbName || ""}
            placeholder="Enter DB name"
            onChange={handleChange}
            error={error.dbName}
            errorMessage="Database name cannot be empty"
        />
        <TextBox
            label="Username"
            type="text"
            name="dbUser"
            id="dbUser"
            value={formData?.metadata?.dbUser || ""}
            placeholder="DB Username"
            onChange={handleChange}
            error={error.dbUser}
            errorMessage="Username cannot be empty"
        />
        <TextBox
            label="Password"
            type="password"
            name="dbPassword"
            id="dbPassword"
            value={formData?.metadata?.dbPassword || ""}
            placeholder="DB Password"
            onChange={handleChange}
            error={error.dbPassword}
            errorMessage="Password cannot be empty"
        />
      </div>
  );
}

//
// 3) Main component
//
export default function FileDSConnector({
                                          savedDataSet = {
                                            dataSourceId: '',
                                            dataSourceType: 'file',
                                            dataSourceUrl: '',
                                            dataType: 'text',
                                            idField: '',
                                            searchField: '',
                                            descriptionForModel: '',
                                            returnOriginal: false,
                                          },
                                          editMode = false,
                                          onCancel
                                        }) {
  const rootRef = useRef(null);
  const { appId } = useAppInfo();

  const [formData, setFormData] = useState({
    fileExt: '',
    metadata: savedDataSet
  });

  const [fileObj, setFileObj] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [error, setError] = useState({ ...initErrorObj });

  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    errorMessage: ''
  });

  // --------------------------------------------
  // UTILS: create an upload URL
  // --------------------------------------------
  const createUploadUrl = async () => {
    if (formData.metadata.dataSourceId && formData.metadata.dataType) {
      try {
        const formDataRequest = { ...formData };
        // Convert string searchableFields -> array if necessary
        if (
            !Array.isArray(formDataRequest.metadata.searchableFields) &&
            formDataRequest.metadata.searchableFields
        ) {
          formDataRequest.metadata.searchableFields = formDataRequest.metadata.searchableFields.split(",");
        }
        const { response, status } = await uploadUrlGet(appId, formDataRequest);
        if (status === 200) {
          return response;
        } else {
          throw new Error(response);
        }
      } catch (err) {
        console.error("get-url call failed cause", err);
        throw new Error("createUploadUrl failed");
      }
    }
  };

  // --------------------------------------------
  // SIDE EFFECT: close success message automatically
  // --------------------------------------------
  useEffect(() => {
    if (formState.success) {
      setTimeout(() => {
        onCancel(true);
      }, 1500);
    }
  }, [formState.success, onCancel]);

  // --------------------------------------------
  // HANDLERS
  // --------------------------------------------
  const handleChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setFormData((prevData) => {
      const currData = { ...prevData };
      currData.metadata[targetName] = targetValue;
      return currData;
    });
  };

  const handleSelectFile = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      // set extension
      const extension = file.name.split(".").pop().toLowerCase();
      setFileName(file.name);
      setFileObj(file);

      setFormData((prev) => ({
        ...prev,
        fileExt: extension,
        metadata: {
          ...prev.metadata,
          dataType: extension,
        },
      }));
    }
  };

  // --------------------------------------------
  // FORM VALIDATION
  // --------------------------------------------
  const validateForm = () => {
    console.log('validateForm')
    let valid = true;
    const newErrors = { ...initErrorObj };

    // 1. Validate dataSourceId
    if (
        !formData.metadata.dataSourceId ||
        !validateActionName(formData.metadata.dataSourceId)
    ) {
      newErrors.dataSourceId = true;
      valid = false;
    }

    // 2. Check dataSourceType
    const dsType = formData.metadata.dataSourceType;

    // If "file" => must have a file
    if (dsType === 'file') {
      if (!fileObj) {
        newErrors.file = true;
        valid = false;
      }
    }

    // If "database" => check DB fields (example checks)
    if (dsType === 'database') {
      if (!formData.metadata.dbType) {
        newErrors.dbType = true;
        valid = false;
      }
      if (!formData.metadata.dbHost) {
        newErrors.dbHost = true;
        valid = false;
      }
      if (!formData.metadata.dbPort) {
        newErrors.dbPort = true;
        valid = false;
      }
      if (!formData.metadata.dbName) {
        newErrors.dbName = true;
        valid = false;
      }
    }

    console.log('Error', newErrors)
    setError(newErrors);
    return valid;
  };

  const updateFormState = (processing, success, failed, errorMessage = '') => {
    setFormState({ processing, success, failed, errorMessage });
  };

  // --------------------------------------------
  // SUBMIT
  // --------------------------------------------
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      const dsType = formData.metadata.dataSourceType;
      try {
        updateFormState(true, false, false);

        // -- FILE branch
        if (dsType === 'file') {
          const { uploadUrl, metadata: uploadMetadata } = await createUploadUrl();
          // 1) upload file
          const response = await fileUpload(uploadUrl, fileObj);
          // 2) create data source
          await dataSourceCreate(appId, uploadMetadata);

          if (response) {
            updateFormState(false, true, false);
          } else {
            throw new Error("fileUpload failed");
          }
        }

        // -- DATABASE branch
        if (dsType === 'database') {
          await dataSourceCreate(appId, formData.metadata);
          updateFormState(false, true, false);
        }
      } catch (e) {
        console.error(e);
        updateFormState(false, false, true, 'Unable to upload. Please try again');
      }
    }
  };

  // --------------------------------------------
  // RENDER
  // --------------------------------------------
  return (
      <div ref={rootRef}>
        <Modal show={true} size="md" popup onClose={onCancel} root={rootRef.current ?? undefined}>
          <Modal.Header className="-mb-5 z-10" />
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <div className="space-y-6">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white">{editMode? 'Edit Data Source': 'New Data Source'}</h3>
                {formState.success && (
                    <Message
                        text={`Successfully ${formData.id ? 'Updated' : 'Saved'} Data Source`}
                        type="success"
                    />
                )}
                {formState.failed && (
                    <Message text={formState.errorMessage} type="failure" />
                )}
              </div>

              {/* COMMON FIELDS */}
              <div className="flex flex-col gap-4 pb-6 mt-4">
                <TextBox
                    label="Name"
                    type="text"
                    name="dataSourceId"
                    id="dataSourceId"
                    value={formData?.metadata?.dataSourceId}
                    placeholder="Name of the data source"
                    onChange={handleChange}
                    error={error.dataSourceId}
                    errorMessage="Name must only contain letters, numbers, underscores (_), or hyphens (-). No spaces."
                />

                <SelectInput
                    label="Source Type"
                    id="dataSourceType"
                    name="dataSourceType"
                    autoComplete="dataSourceType"
                    value={formData?.metadata?.dataSourceType}
                    onChange={handleChange}
                    optionsComp={
                      <>
                        <option value="file">Document</option>
                        <option value="database">Database</option>
                      </>
                    }
                />
              </div>

              {/* CONDITIONAL FIELDS */}
              {formData.metadata.dataSourceType === 'file' && (
                  <FileFields
                      fileName={fileName}
                      handleSelectFile={handleSelectFile}
                      error={error}
                  />
              )}

              {formData.metadata.dataSourceType === 'database' && (
                  <DatabaseFields
                      formData={formData}
                      handleChange={handleChange}
                      error={error}
                  />
              )}

              {/* ACTION BUTTONS */}
              <div className="space-y-6 pt-6">
                <div className="w-full flex flex-row gap-2 justify-end">
                  <Button color="default" text="Cancel" onClick={onCancel} />
                  <Button
                      type={formState.processing ? 'button' : 'submit'}
                      text="Save Data Source"
                      loading={formState.processing}
                  />
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
  );
}
