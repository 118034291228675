import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/configure-store';
import config from './config';
import { datadogRum } from '@datadog/browser-rum';
import {EventBusProvider} from "./helpers/hooks/event-bus";
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import {providers} from "./configs/ProviderInjection";

const getAppWithContextProviders = () => {

    let result = <App/>
    providers.forEach(Provider => result = <Provider>{result}</Provider>);

    return result;
}

if (config.IS_PRODUCTION) {
    // console.log = () => {}
    // console.debug = () => {}
    datadogRum.init({
        applicationId: '52a9fd30-a4e9-49ac-9704-73c2ac331216',
        clientToken: 'pub2e170a2f2dcca4f9ad209dbaf0bb684d',
        site: 'datadoghq.com',
        service: 'platform.botcircuits.com',
        env: 'prod',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });

    // amplitude setup
    amplitude.init(config.AMPLITUDE_API_KEY, { defaultTracking: true })
    const sessionReplayTracking = sessionReplayPlugin({
        sampleRate: 1
    });
    amplitude.add(sessionReplayTracking);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Router>
            <Provider store={store}>
                <EventBusProvider>
                    {getAppWithContextProviders()}
                </EventBusProvider>
            </Provider>
        </Router>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
