import {addEdge, Background, Controls, MarkerType, ReactFlow, useReactFlow} from 'reactflow';
import {memo, useEffect, useContext, useMemo, useRef, useState, useCallback} from "react";
import '@xyflow/react/dist/style.css';
import {WorkflowCanvasContext} from "../../contexts/WorkflowCanvasContextProvider";
import MainWorkflowNode from "./WorkflowNode";
import CustomEdge from "./CustomEdge";
// import WorkflowDrawer from "./WorkflowDrawer";
// import WorkflowConditionModal from "./ConditionNodeModal";
import {NOTE_EDGE_NODE_TYPE, NOTE_NODE_TYPE} from "../../helpers/Constant";
import {moveConditionToLast} from "../../helpers/Utils";
import NoteNode from "./NoteNode";
import NoteEdgeConnectionNode from "./NoteEdgeConnectionNode";
import StartNode from "./StartNode";
import {CHOICE_NODE_TYPE, START_NODE_COMPONENT} from "../../pages/action-journey-studio/common/const/node-types";
import Settings from "../../pages/action-journey-studio/settings";
import {DrawerPanel} from "../../pages/action-journey-studio/ui/drawer";
import {journeyGet} from "../../services/model/journey-service";
import journeyStmStore from "../../pages/action-journey-studio-v2/common/service/journey-stm-store";
import {useAppInfo} from "../../helpers/hooks/common-hook";
import {useParams} from "react-router";
import {CgSpinner} from "react-icons/cg";
import {Alert} from "flowbite-react";
import {HiInformationCircle} from "react-icons/hi";
import {timeFormatOptions} from "../../consts/common-consts";
import WorkflowConditionModal from "./ConditionNodeModal";
import {START} from "../../pages/action-journey-studio/common/const/settings-type";
import {createTimeBasedUniqueNumber} from "../../helpers/utils/object-util";
import { formatDistanceToNow } from "date-fns";

const BackgroundSyncLoader = () => (
    <div className="absolute modified-at text-right flex text-sky-800 gap-2 top-3">
        <CgSpinner color="rgb(7 89 133)" size={24} className="animate-spin" />
        Syncing...
    </div>
);

const WorkflowCanvas = ({journeyId, currentJourneySession, currentJourneyConfig}) => {

    const { appId } = useAppInfo();

    const reactFlowInstance = useReactFlow();

    const workflowCanvasContext = useContext(WorkflowCanvasContext);

    const reactFlowWrapper = useRef(null);

    const backgroundSyncActive = workflowCanvasContext.backgroundSyncActive;
    const saveSuccess = workflowCanvasContext.saveSuccess;
    const isPreview = workflowCanvasContext.isPreview;

    const [lastModifiedTime, setLastModifiedTime] = useState();

    const [dataLoadingState, setDataLoading] = useState({
        processing: false,
        success: false,
        failed: false,
        message: null,
    });

    const updateDataLoadingState = (processing, success, failed, message) => {
        setDataLoading(Object.assign({}, { processing, success, failed, message }));
    };

    const updateModifiedTime = (modifiedTime) => {
        const time = modifiedTime ? new Date(modifiedTime) : new Date();
        // const readableDate = new Intl.DateTimeFormat('en-US', timeFormatOptions).format(time);
        const readableDate = formatDistanceToNow(time, { addSuffix: true })
        setLastModifiedTime(readableDate);
    }

    const fetchJourneyData = async () => {
        try {
            updateDataLoadingState(true, false, false);
            let savedJourney
            if (currentJourneyConfig) {
                savedJourney = currentJourneyConfig
            } else {
                savedJourney = await journeyGet(appId, journeyId);
            }

            const { modifiedAt } = savedJourney;
            if (modifiedAt) {
                updateModifiedTime(modifiedAt);
            }

            workflowCanvasContext.setMetadata({
                appId,
                journeyId,
                slots: savedJourney.slots || {}
            })

            if (
                savedJourney?.stm?.metadata?.nodes
            ) {
                workflowCanvasContext.setNodes(savedJourney?.stm?.metadata?.nodes);
                workflowCanvasContext.setEdges(savedJourney?.stm?.metadata?.edges);
            } else {
                const id = createTimeBasedUniqueNumber()
                workflowCanvasContext.setNodes([
                    {
                        id,
                        position: { x: 0, y: 0 },
                        type: START_NODE_COMPONENT,
                        data: {
                            stateConfig: {
                                stateType: START
                            }
                        },
                    }
                ]);
            }
            updateDataLoadingState(false, true, false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (journeyId === undefined) return;
        fetchJourneyData();
    }, [journeyId]);

    useEffect(() => {
        if (currentJourneyConfig === undefined) return;
        fetchJourneyData();
    }, [currentJourneyConfig]);

    useEffect(() => {
        if (saveSuccess) {
            updateModifiedTime()
        }
    }, [saveSuccess]);

    useEffect(() => {
        if (currentJourneyConfig) {
            console.log('currentJourneyConfig')
        }
    }, [currentJourneyConfig]);

    useEffect(() => {
        if (
            reactFlowInstance &&
            currentJourneySession &&
            currentJourneySession.runningBlock
        ) {

            const targetNode = reactFlowInstance.getNode(
                currentJourneySession.runningBlock
            );

            console.log("found currentJourneyConfig", currentJourneyConfig);
            console.log("found targetNode", targetNode);
            if (targetNode) {
                // Reset the current view before focusing on the new node
                reactFlowInstance.setViewport({ x: 0, y: 0, zoom: 1 });

                // Zoom into the node using fitView
                reactFlowInstance.fitView({
                    duration: 800,
                    nodes: [targetNode],
                    padding: 0.8, // Adjust the padding to control how much the view is zoomed
                    minZoom: 0.2, // Slight zoom for better focus
                    maxZoom: 0.7, // Optional: limit the maximum zoom level
                });
            }
        }
    }, [currentJourneySession, reactFlowInstance]);

    const nodeTypes = useMemo(() => ({
        StartNode,
        MainWorkflowNode,
        NoteNode,
        NoteEdgeConnectionNode,
    }), []);

    const edgeTypes = useMemo(() => ({
        custom: CustomEdge,
    }), []);

    const handleConnectStart = useCallback((event, {nodeId, handleId}) => {
        workflowCanvasContext.setConnectingSource({
            nodeId,
            handleId
        })
    }, [])

    const handleNodeMouseEnter = (event, node) => {
        workflowCanvasContext.setConnectingTarget(node);
    }

    const handleConnectEnd = (event) => {
        console.log('handleConnectEnd connectingSource', workflowCanvasContext.connectingSource);
        console.log('handleConnectEnd connectingTarget', workflowCanvasContext.connectingTarget);
        if (workflowCanvasContext.connectingSource && workflowCanvasContext.connectingTarget) {
            const sourceId = workflowCanvasContext.connectingSource.nodeId
            const targetId = workflowCanvasContext.connectingTarget.id
            const newEdge = {
                id: `edge-${sourceId}-${targetId}`,
                source: sourceId,
                target: targetId,
                sourceHandle: workflowCanvasContext.connectingSource.handleId,
                targetHandle: `target-${targetId}-left-top`,
                type: 'custom',
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 20,
                    height: 20,
                    color: '#969b9f',
                },
                style: {
                    strokeWidth: 2,
                    stroke: '#969b9f',
                },
            };

            const startedNodeData = workflowCanvasContext.nodes.find((node) => node.id === sourceId);
            let isConditionalBlock = false;
            if (startedNodeData && startedNodeData.data && startedNodeData.data.children) {
                startedNodeData?.data?.children?.forEach(item => {
                    if (item.nodeType === CHOICE_NODE_TYPE) {
                        isConditionalBlock = true;
                    }
                })
            }

            if (isConditionalBlock) {
                workflowCanvasContext.setEdges((eds) => addEdge(newEdge, eds));
            } else {
                workflowCanvasContext.setEdges((eds) =>
                    addEdge(newEdge, eds.filter((edge) => edge.source !== sourceId))
                );
            }
        }

        workflowCanvasContext.setConnectingSource(null)
        workflowCanvasContext.setConnectingTarget(null);
    }

    const onDrop = (event) => {

        event.preventDefault();

        const reactFlowBounds = reactFlowWrapper.current?.getBoundingClientRect()

        const reactFlowPosition = reactFlowInstance.project({
            x: event.clientX - (reactFlowBounds?.left ?? 0),
            y: event.clientY - (reactFlowBounds?.top ?? 0),
        });

        const nodeAtPosition = workflowCanvasContext.nodes.find(node => {
            const nodeX = node.position.x;
            const nodeY = node.position.y;
            const nodeWidth = node.width;
            const nodeHeight = node.height;

            return (
                reactFlowPosition.x > nodeX &&
                reactFlowPosition.x < nodeX + nodeWidth &&
                reactFlowPosition.y > nodeY &&
                reactFlowPosition.y < nodeY + nodeHeight
            );
        })

        if (nodeAtPosition) {
            return
        } else {
            if (event.dataTransfer.getData('nodeData')) {

                const parseNodeData = JSON.parse(event.dataTransfer.getData('nodeData'));

                console.log('parseNodeData', parseNodeData)

                let previousData = workflowCanvasContext.nodes;
                let findNode;

                if (parseNodeData.nodeType === NOTE_NODE_TYPE || parseNodeData.nodeType === NOTE_EDGE_NODE_TYPE) {

                    const reactFlowBoundsClient = event.target.getBoundingClientRect();

                    const position = {
                        x: event.clientX - reactFlowBoundsClient.left,
                        y: event.clientY - reactFlowBoundsClient.top
                    };

                    const noteNode = {
                        id: createTimeBasedUniqueNumber(),
                        position,
                        type: parseNodeData.nodeType === NOTE_NODE_TYPE ? "NoteNode" : "NoteEdgeConnectionNode",
                        data: {
                            txt: "",
                            nodeType: parseNodeData.nodeType
                        },
                    }


                    workflowCanvasContext.setNodes(prev => [...prev, noteNode]);

                    return;
                }

                if (parseNodeData.blockId) {

                    const _nodes = workflowCanvasContext.nodes.map(node => {
                        if (node.id === parseNodeData.blockId) {

                            const updatedNodes = node.data.children.filter(item => item.id !== parseNodeData.childrenId);
                            findNode = node.data.children.find(item => item.id === parseNodeData.childrenId);

                            if (updatedNodes.length > 0) {
                                return (
                                    {
                                        ...node,
                                        data: {
                                            ...node.data,
                                            children: updatedNodes,
                                        }
                                    }
                                )
                            }
                            return undefined;
                        }
                        return node;
                    }).filter(node => node !== undefined);

                    previousData = _nodes;
                }

                const reactFlowBoundsClient = event.target.getBoundingClientRect();

                const position = {
                    x: event.clientX - reactFlowBoundsClient.left,
                    y: event.clientY - reactFlowBoundsClient.top
                };


                const newNodeId = createTimeBasedUniqueNumber();
                const nodeChildId = +createTimeBasedUniqueNumber();

                let newNode;

                if (parseNodeData.nodeType === CHOICE_NODE_TYPE) {

                    if (findNode) {
                        newNode = {
                            id: newNodeId,
                            position,
                            data: {
                                label: `New Block`,
                                children: [findNode]
                            },
                            type: "MainWorkflowNode",
                            dragHandle: '.custom-drag-handle',
                            style: {
                                borderRadius: "12px",
                                width: "300px",
                                // backgroundColor: '#FFFFFF',
                            },
                        }
                    } else {
                        newNode = {
                            id: newNodeId,
                            position,
                            data: {
                                label: `New Block`,
                                children: [
                                    {
                                        id: nodeChildId,
                                        label: parseNodeData.label,
                                        nodeType: parseNodeData.nodeType,
                                        stateConfig: {
                                            choices: []
                                        }
                                    },
                                ]
                            },
                            type: "MainWorkflowNode",
                            dragHandle: '.custom-drag-handle',
                            style: {
                                borderRadius: "12px",
                                width: "300px",
                                // backgroundColor: '#FFFFFF',
                            },
                        }
                    }
                } else {

                    if (findNode) {
                        newNode = {
                            id: newNodeId,
                            position,
                            data: {
                                label: `New Block`,
                                children: [findNode]
                            },
                            type: "MainWorkflowNode",
                            dragHandle: '.custom-drag-handle',
                            style: {
                                borderRadius: "12px",
                                width: "300px",
                                // backgroundColor: '#FFFFFF',
                            },
                        }
                    } else {
                        newNode = {
                            id: newNodeId,
                            position,
                            data: {
                                label: `New Block`,
                                children: [
                                    {
                                        id: nodeChildId,
                                        label: parseNodeData.label,
                                        nodeType: parseNodeData.nodeType,
                                        stateConfig: parseNodeData.stateConfig
                                    },
                                ]
                            },
                            type: "MainWorkflowNode",
                            dragHandle: '.custom-drag-handle',
                            style: {
                                borderRadius: "12px",
                                width: "300px",
                                // backgroundColor: '#FFFFFF',
                            },
                        }
                    }
                }

                console.log('WC previousData', previousData)
                console.log('WC newNode', newNode)
                workflowCanvasContext.setNodes([...previousData, newNode]);

            }
        }

    };

    const [dragStartPos, setDragStartPos] = useState(null);
    const {getNodes} = useReactFlow();

    const [viewport, setViewport] = useState({x: 0, y: 0, zoom: 0.1});
    useEffect(() => {
        // Center align viewport based on container size and initial zoom level
        const {width, height} = reactFlowWrapper.current.getBoundingClientRect();
        const centerX = width / 2;
        const centerY = height / 2;

        setViewport({
            x: centerX - (width * viewport.zoom) / 2,
            y: centerY - (height * viewport.zoom) / 2,
            zoom: 0.1,
        });
    }, []);

    const onNodeDragStart = (event, node) => {
        // Capture the initial position when dragging starts
        setDragStartPos({x: node.position.x, y: node.position.y});
    };

    const checkCollision = (draggedNode, targetNode) => {
        const draggedRect = {
            x: draggedNode.position.x,
            y: draggedNode.position.y,
            width: draggedNode.width || 100, // assuming width if not defined
            height: draggedNode.height || 50 // assuming height if not defined
        };

        const targetRect = {
            x: targetNode.position.x,
            y: targetNode.position.y,
            width: targetNode.width || 100, // assuming width if not defined
            height: targetNode.height || 50 // assuming height if not defined
        };

        return !(
            draggedRect.x > targetRect.x + targetRect.width ||
            draggedRect.x + draggedRect.width < targetRect.x ||
            draggedRect.y > targetRect.y + targetRect.height ||
            draggedRect.y + draggedRect.height < targetRect.y
        );
    };

    const onNodeDragStop = (event, draggedNode) => {

        if (draggedNode.data.nodeType === NOTE_EDGE_NODE_TYPE || draggedNode.data.nodeType === NOTE_NODE_TYPE) {
            return;
        }

        const hasMoved = dragStartPos && (dragStartPos.x !== draggedNode.position.x || dragStartPos.y !== draggedNode.position.y);

        if (hasMoved) {
            // Get all nodes in the React Flow graph
            const allNodes = getNodes();

            // Check for collisions with other nodes
            allNodes.forEach((targetNode) => {
                if (targetNode.id !== draggedNode.id) {
                    if (checkCollision(draggedNode, targetNode)) {
                        // Your custom logic when a block is dropped on another node (targetNode)
                        const targetBlock = workflowCanvasContext.nodes.find((node) => node.id === targetNode.id);
                        const draggedBlock = workflowCanvasContext.nodes.find((node) => node.id === draggedNode.id);

                        if (targetBlock.data.nodeType !== NOTE_EDGE_NODE_TYPE && targetBlock.data.nodeType !== NOTE_NODE_TYPE) {

                            let isTargetConditional;
                            targetBlock.data.children.forEach(item => {
                                if (item.nodeType === CHOICE_NODE_TYPE) {
                                    isTargetConditional = true;
                                }
                            })
                            let isDraggedConditional;
                            draggedBlock.data.children.forEach(item => {
                                if (item.nodeType === CHOICE_NODE_TYPE) {
                                    isDraggedConditional = true;
                                }
                            })
                            if (isTargetConditional === true && isDraggedConditional === true) {
                            } else {
                                targetBlock.data.children = moveConditionToLast([...draggedBlock.data.children, ...targetBlock.data.children]);
                                const newNodes = workflowCanvasContext.nodes.filter((node) => node.id !== draggedNode.id && node.id !== targetNode.id);
                                workflowCanvasContext.setNodes([...newNodes, targetBlock]);
                            }
                        }

                    }
                }
            });
        }

        // Reset the drag start position after the drag ends
        setDragStartPos(null);
        workflowCanvasContext.onEdgeUpdateForMove(event, draggedNode)
    };


    const onDrawerClose = () => {

        workflowCanvasContext.closeNodeDrawer();

        if (workflowCanvasContext.nodeAttribute.nodeType !== CHOICE_NODE_TYPE) {

            const contentData = {...workflowCanvasContext.nodeAttribute};
            delete contentData.parentId;
            delete contentData.id;

            const selectedBlock = workflowCanvasContext.nodes
                .find(item => item.id === workflowCanvasContext.nodeAttribute.parentId);

            selectedBlock.data.children = selectedBlock.data.children.map(item => {
                if (item.id === workflowCanvasContext.nodeAttribute.id) {
                    return {
                        ...item,
                        stateConfig: contentData.stateConfig
                    }
                }
                return item;
            });

            const findIndex = workflowCanvasContext.nodes
                .findIndex(item => item.id === workflowCanvasContext.nodeAttribute.parentId);

            workflowCanvasContext.nodes[findIndex] = selectedBlock;

            workflowCanvasContext.setNodes([...workflowCanvasContext.nodes]);
        }

    }

    if (dataLoadingState.processing) {
        return (
            <div className="flex flex-grow items-center justify-center p-3">
                <div className="flex items-center gap-1 text-gray-500">
                    <CgSpinner size={24} className="animate-spin" />
                    Loading..
                </div>
            </div>
        );
    }

    if (dataLoadingState.failed) {
        return (
            <Alert color="failure" icon={HiInformationCircle}>
                <span className="font-medium">Error!</span> We're sorry, but we couldn't
                load the selected journey.
            </Alert>
        );
    }

    const onEdgeUpdateStart = () => {
        console.log('onEdgeUpdateStart')
    }

    const onEdgeUpdate = () => {
        console.log('onEdgeUpdate')
    }

    return (
        <>

            {(!isPreview && backgroundSyncActive) && (
                <BackgroundSyncLoader />
            )}
            {(!isPreview && lastModifiedTime && !backgroundSyncActive) && (
                <div className="absolute modified-at text-right">
                    <small className="text-gray-500 dark:text-gray-300">Last modified</small>
                    <div className="dark:text-gray-500">{lastModifiedTime}</div>
                </div>
            )}

            {/*<p className="">{workflowCanvasContext.nodes.length}</p>*/}

            {!isPreview &&
                <DrawerPanel
                    key={journeyId}
                    open={workflowCanvasContext.nodeDrawerVisible}
                    onClose={() => onDrawerClose()}
                >
                    <Settings
                        onUpdate={()=>{}}
                        closeSettingPage={()=>{}}
                    />
                </DrawerPanel>
            }


            <div className="flex-grow h-full" ref={reactFlowWrapper}>
                {isPreview?
                    <ReactFlow
                        proOptions={{hideAttribution: true}}
                        nodes={workflowCanvasContext.nodes}
                        edges={workflowCanvasContext.edges}
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}
                        fitView={true}
                        snapToGrid={true}
                    >
                        <Background/>

                        <Controls
                            style={{display: "flex", flexDirection: "row"}}
                        />

                    </ReactFlow>
                    :
                    <ReactFlow
                        proOptions={{hideAttribution: true}}
                        nodes={workflowCanvasContext.nodes}
                        edges={workflowCanvasContext.edges}
                        onNodesChange={workflowCanvasContext.onNodesChange}
                        onEdgesChange={workflowCanvasContext.onEdgesChange}
                        onConnect={workflowCanvasContext.onConnect}
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}
                        onDrop={onDrop}
                        // onDrop={workflowCanvasContext.onDrop}
                        onConnectStart={handleConnectStart}
                        onNodeMouseEnter={handleNodeMouseEnter}
                        onConnectEnd={handleConnectEnd}
                        onNodeDragStart={onNodeDragStart} // Track when dragging starts
                        onNodeDragStop={onNodeDragStop}
                        onDragOver={workflowCanvasContext.onDragOver}
                        onEdgeUpdateStart={onEdgeUpdateStart}
                        onEdgeUpdate={onEdgeUpdate}
                        // fitView={true}
                        // style={{width: "100%", height: "100%"}}
                        defaultViewport={viewport} // Setting zoom close to 0
                    >
                        <Background/>

                        <Controls
                            style={{display: "flex", flexDirection: "row"}}
                        />

                    </ReactFlow>
                }


            </div>

            <WorkflowConditionModal/>

        </>
    );
}

export default memo(WorkflowCanvas);
