import { AiOutlineSetting } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { FaWhatsapp } from "react-icons/fa";
import { GoShareAndroid } from "react-icons/go";
import { HiOutlineHashtag } from "react-icons/hi";
import { LuWorkflow } from "react-icons/lu";
import { IoChatboxEllipsesOutline, IoCube } from "react-icons/io5";
import { BiSupport } from "react-icons/bi";
import { IoIosArrowRoundBack } from "react-icons/io";


import { LuCodesandbox } from "react-icons/lu";
import {
  MdOutlineInsertChart
} from "react-icons/md";
import { PiBookOpen } from "react-icons/pi";
import { TbCash } from "react-icons/tb";

export const AGENTS_APP_INDEX = 1000;

const userProfileEndpoint = {
  name: "User Profile",
  href: "/user-settings/profile",
  active: true,
  icon: <CgProfile />,
};

const billingEndpoint = {
  name: "Subscription",
  href: "/user-settings/subscription",
  active: false,
  icon: <TbCash />,
};

const applicationEndpoint = {
  name: "Assistants",
  href: "/",
  active: true,
  icon: <IoCube />,
};

export const userSettingsNavigation = [userProfileEndpoint, billingEndpoint];

export const appsNavigation = [];

// default sidebar paths
export const appNavigation = [
  {
    name: "Agent Flows",
    href: "/journey",
    active: false,
    icon: <LuWorkflow />,
    tip: "Agent flow editor",
  },
  {
    name: "Data Sources",
    icon: <PiBookOpen />,
    href: "/contents",
    // subMenu: true,
    // subMenuItems: [
    //   {
    //     name: "Functions",
    //     href: "/functions",
    //     active: false,
    //     icon: <BsCodeSlash />,
    //     tip: "Functions allow you to input custom instructions ( code in your preferred programming language ) that can be set up under actions.",
    //   },
    //   {
    //     name: "Knowledge Base",
    //     href: "/knowledge-base/docs/data-sources",
    //     active: false,
    //     icon: <TbDatabaseEdit />,
    //     tip: "Knowledge base is where you provide data sources for the agent, currently supporting docx, csv, and pdf.",
    //   },
    //   {
    //     name: "Instructions",
    //     href: "/agent/settings",
    //     active: false,
    //     icon: <MdOutlineIntegrationInstructions />,
    //     tip: "Settings to handle behaviour of conversation flow",
    //   },
    // ],
  },
  {
    name: "Channels",
    href: "/channels",
    active: false,
    icon: <FaWhatsapp />,
    tip: "Various platforms where you can deploy your agent.",
  },
  {
    name: "Integrations",
    href: "/integrations/workflow",
    active: false,
    icon: <GoShareAndroid />,
    tip: "Connections to 3rd party platforms that allow you to retrieve data or perform other supported actions.",
  },
  {
    name: "Conversations",
    href: "/hitl",
    active: false,
    icon: <BiSupport />,
    tip: "Human-in-the-Loop",
  },
  {
    name: "Analytics",
    href: "/analytics",
    active: false,
    icon: <MdOutlineInsertChart />,
    tip: "Analyzing Conversations for Deeper Insights",
  },
  {
    name: "Settings",
    href: "/settings",
    active: false,
    icon: <AiOutlineSetting />,
    tip: "Set up your agent's basic details, create API keys, and manage live agent transfers.",
  },
];

// bottom of the sidebar paths
export const appNavigationEnd = [
  {
    name: "My Applications",
    href: "/",
    active: false,
    icon: <LuCodesandbox />,
    tip: "Application list",
    resetContext: true
  },
];
