import {Card, Modal, Badge } from "flowbite-react";
import {useEffect, useRef, useState} from "react";
import {fetchTemplate} from "../../../services/app/app-service";
import {Alert} from 'flowbite-react';
import DropDown from "../../../components/forms/dropdown";
import {INDUSTRIES, USE_CASES} from "./consts/selector-consts";
import Loader from "../../../components/form-loader/form-loader";
import LogoBox from "../../../assets/images/logo/logo-box.png";

function AppTemplateSelector({onCompletion}) {
    const rootRef = useRef(null);
    const [dataList, setDataList] = useState([]);
    const [dataLoadingState, setDataLoadingState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: ''
    });

    async function fetchDataList() {
        updateFormState(true, false, false);
        const { data } = await fetchTemplate()
        setDataList(data);
        updateFormState(false, true, false);
    }

    const updateFormState = (processing, success, failed, message) => {
        setDataLoadingState({ processing, success, failed, message })
    }

    useEffect(() => {
        fetchDataList();
    }, []);

    return (
        <div ref={rootRef}>
            <Modal show={true} size="6xl" popup onClose={()=>onCompletion()} root={rootRef.current ?? undefined}>
                <Modal.Header></Modal.Header>
                <Modal.Body>
                    {dataLoadingState.processing && <Loader text="Loading..." fullScreen={true} />}
                    <div className="space-y-6">
                        <div className="flex flex-row justify-between items-center">
                            <h3 className="text-xl font-medium text-gray-900 dark:text-white">Agent Templates</h3>
                            {/*<div className="flex flex-row items-center gap-6">*/}
                            {/*    <DropDown*/}
                            {/*        className=""*/}
                            {/*        selectedOptionClassName="w-full"*/}
                            {/*        optionClassName="capitalize w-full"*/}
                            {/*        placeHolder="Industries"*/}
                            {/*        name="dataType"*/}
                            {/*        options={INDUSTRIES.map(i=>({label: i}))}*/}
                            {/*        onChange={()=>{}}*/}
                            {/*    />*/}

                            {/*    <DropDown*/}
                            {/*        className=""*/}
                            {/*        selectedOptionClassName="w-full"*/}
                            {/*        optionClassName="capitalize w-full"*/}
                            {/*        placeHolder="Use Cases"*/}
                            {/*        name="dataType"*/}
                            {/*        options={USE_CASES.map(i=>({label: i}))}*/}
                            {/*        onChange={()=>{}}*/}
                            {/*    />*/}

                            {/*</div>*/}
                        </div>

                        {dataLoadingState.success && dataList.length === 0 &&
                            <Alert
                                color="dark"
                                rounded
                            >
                            <span>
                                <p>
                                    Templates Not Available
                                </p>
                            </span>
                            </Alert>
                        }

                        <div className="bg-gray-50 p-8 h-screen">
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                                {dataList.map((template) => (
                                    <div
                                        onClick={()=>onCompletion(template)}
                                        key={template.title}
                                        className="bg-white shadow-md rounded-lg overflow-hidden cursor-pointer"
                                    >
                                        <img
                                            src={LogoBox}
                                            className="h-48 w-full object-cover"
                                        />
                                        <div className="p-4">
                                            <p className="text-gray-800 font-medium truncate">{template.title}</p>
                                            <p className="text-gray-600 text-sm">By {template.developer}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/*<div className="grid grid-cols-3 gap-6">*/}

                        {/*    {dataList.map((template, index)=>*/}
                        {/*        <Card key={index} className="max-w-sm bg-gray-50 cursor-pointer hover:bg-gray-200" onClick={()=>onCompletion(template)}>*/}
                        {/*            <h4 className="text-md font-bold text-gray-900 dark:text-white">*/}
                        {/*                {template.title}*/}
                        {/*            </h4>*/}
                        {/*            <p className="text-sm text-gray-700 dark:text-gray-400">*/}
                        {/*                {template.description}*/}
                        {/*            </p>*/}

                        {/*            {template.sampleQueries &&*/}
                        {/*                <div className="">*/}
                        {/*                    <p className="text-xs font-bold text-gray-500 dark:text-gray-400">*/}
                        {/*                        Sample queries*/}
                        {/*                    </p>*/}
                        {/*                    <blockquote*/}
                        {/*                        className="p-4 my-4 border-s-4 border-gray-300 bg-gray-50 dark:border-gray-500 dark:bg-gray-800">*/}
                        {/*                        {template.sampleQueries.map((query, index)=>*/}
                        {/*                            <p key={index} className="text-sm font-medium leading-relaxed text-gray-500 dark:text-white">"{query}"</p>*/}
                        {/*                        )}*/}

                        {/*                    </blockquote>*/}
                        {/*                </div>*/}
                        {/*            }*/}

                        {/*            <div className="w-3/4 flex flex-row gap-2">*/}
                        {/*                <Badge size="xs" key={index} color="pink">*/}
                        {/*                    {template.useCase}*/}
                        {/*                </Badge>*/}
                        {/*                <Badge size="xs" key={index} color="pink">*/}
                        {/*                    {template.industry}*/}
                        {/*                </Badge>*/}
                        {/*            </div>*/}


                        {/*            {template.features &&*/}
                        {/*                <div className="w-3/4 flex flex-wrap gap-2">*/}
                        {/*                    {template.features.map((feature, index) =>*/}
                        {/*                        <Badge size="xs" key={index}>*/}
                        {/*                            {feature.toLowerCase()}*/}
                        {/*                        </Badge>*/}
                        {/*                    )}*/}
                        {/*                </div>*/}
                        {/*            }*/}

                        {/*            /!*<div className="flex flex-row items-center justify-end">*!/*/}
                        {/*            /!*    <Button text="Select Template"/>*!/*/}
                        {/*            /!*</div>*!/*/}

                        {/*            <p className="text-xs font-normal text-gray-500 dark:text-gray-400">*/}
                        {/*                By {template.developer}*/}
                        {/*            </p>*/}

                        {/*        </Card>*/}
                        {/*    )}*/}

                        {/*</div>*/}

                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AppTemplateSelector;
