import { useEffect, useState, useContext } from "react";
import { HiOutlinePlus } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import {useAppInfoFromUralParams, useQuery} from "../../../../helpers/hooks/common-hook";
import { getAgentActions } from "../../../../services/prompt-ai";
import { ButtonIcon } from "../../ui/button";
import { Tooltip } from "flowbite-react";
import JourneyCreateDialog from "../journey-create";
import { List } from "./list";
import awsExports from "../../../../aws-exports";
import {WorkflowCanvasContext} from "../../../../contexts/WorkflowCanvasContextProvider";

export default function JourneyList() {
  const workflowCanvasContext = useContext(WorkflowCanvasContext);
  const { appId } = useAppInfoFromUralParams();
  const navigate = useNavigate();
  const { journeyId: journeyParam } = useParams();

  const query = useQuery();
  const isInit = query.get('init');

  const [dataList, setDataList] = useState([]);
  // const [searchParam, setSearchParam] = useState("");
  // const [filteredDataList, setFilteredDataList] = useState([]);
  const [openJourneyCreate, setOpenJourneyCreate] = useState(false);

  const [selectedJourney, setSelectedJourney] = useState(null);

  const [dataLoadingState, setDataLoading] = useState({
    processing: false,
    success: false,
    failed: false,
    message: null,
  });

  const fetchConfigs = async () => {
    const fetchCall = async ()=> {
      updateDataLoadingState(true, false, false);
      const settings = await getAgentActions(appId);
      if (settings.data) {
        setDataList(settings.data);
        updateDataLoadingState(false, true, false);
      }
      return settings.data
    }

    if (isInit) {
      const handler = setInterval(async () => {
        const result = await fetchCall()
        if (result) {
          clearInterval(handler)
        }
      }, 1000)
    } else {
      await fetchCall()
    }
  };

  const updateDataLoadingState = (processing, success, failed, message) => {
    setDataLoading({ processing, success, failed, message });
  };

  const handleOnClickCreateJourney = () => {
    setOpenJourneyCreate(true);
  };

  const handleOnCancelJourneyCreate = () => {
    setOpenJourneyCreate(false);
  };

  const handleOnSuccessJourneyCreate = () => {
    setOpenJourneyCreate(false);
    fetchConfigs()
  }

  const loadCanvas = () => {
    // TODO load according to saved data
    workflowCanvasContext.setNodes([]);
    workflowCanvasContext.setEdges([]);
  }

  useEffect(() => {
    fetchConfigs();
  }, []);

  useEffect(() => {
    if (dataLoadingState.success && dataList?.length === 0) {
      // if we don't have journey open dialog
      // setOpenJourneyCreate(true);
    } else if (dataLoadingState.success && dataList) {
      setSelectedJourney(journeyParam || dataList[0].id);
    }
  }, [dataList, journeyParam, dataLoadingState.success]);

  useEffect(() => {
    if (appId && selectedJourney) {
      loadCanvas()
      navigate(`/app/${appId}/journey/${selectedJourney}`);
    }
  }, [selectedJourney, appId]);

  const onDeleteComplete = async () => {
    fetchConfigs()
  }

  return (
    <aside className="flex h-[calc(100vh-90px)] bg-white dark:bg-slate-800 w-full flex-col overflow-y-hidden sm:w-[260px] xl:w-[300px] border rounded-lg dark:border-gray-700 shadow-md">
      {openJourneyCreate && (
        <JourneyCreateDialog
          onCompletion={handleOnSuccessJourneyCreate}
          onCancel={handleOnCancelJourneyCreate}
        />
      )}
      <div class="h-full w-full space-y-8 p-4">
        {/* default journeys */}
        <List
          filterKey="name"
          title="Agents"
          items={dataList}
          status={{ ...dataLoadingState }}
          onDeleteComplete={onDeleteComplete}
          actionButton={
            <>
              {/*<Button onClick={handleOnClickCreateJourney} outline gradientDuoTone="purpleToBlue">*/}
              {/*  <HiOutlinePlus size={17} />*/}
              {/*</Button>*/}
              <Tooltip content="Create Agent">
              <button
                  onClick={handleOnClickCreateJourney}
                  className="
    inline-flex items-center justify-center gap-2 text-sm rounded-md transition h-8 w-8
    border border-indigo-600 text-indigo-600 hover:bg-indigo-100 hover:ring-4 ring-indigo-200
    dark:ring-indigo-900 dark:hover:bg-indigo-800 dark:text-indigo-300 dark:border-indigo-300
  "
                  aria-label="Outline Icon Button"
              >
                <HiOutlinePlus size={17} />
              </button>
              </Tooltip>
            </>
          }
        />
        {/* favorites journeys */}
        {/*<List*/}
        {/*  title="Favorites"*/}
        {/*  filterKey="name"*/}
        {/*  items={[*/}
        {/*    ...Array.from({ length: 20 }).map((_, i) => ({*/}
        {/*      appId: "snpp0zxz41",*/}
        {/*      name: `favorite-journey- ${i + 1}`,*/}
        {/*    })),*/}
        {/*  ]}*/}
        {/*  status={{ ...dataLoadingState }}*/}
        {/*  actionButton={*/}
        {/*    <>*/}
        {/*      <div className="w-9 h-9 inline-flex items-center justify-center">*/}
        {/*        <FaHeart size={17} className="!fill-rose-600" />*/}
        {/*      </div>*/}
        {/*    </>*/}
        {/*  }*/}
        {/*/>*/}
      </div>
    </aside>
  );
}
