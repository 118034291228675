import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import DynamicRows from "../../../../components/dynamic-rows-cards";
import TextArea from "../../../../components/forms/text-area";
import TextBox from "../../../../components/forms/text-box";
import ActionButtons from "../../common/components/buttons-settings";
import DisplayTexts from "../../common/components/display-texts";
import SettingTip from "../../common/components/setting-tip";
import { WORKFLOW_OPTION_SLOT } from "../../common/const/settings-const";
import { QUESTION_PROMPT } from "../../common/const/settings-type";
import { StmStateDto } from "../../common/dto/stm-state-dto";
import CardImageUrl from "./card-image-url";
import isEqual from "lodash/isEqual";
import SlotSelector from "../../common/components/slot-selector";

function Card({ cardConfig, handleInputChange, rawId, metadata }) {
  return (
    <div className="flex flex-col gap-2" key={rawId}>
      <CardImageUrl config={cardConfig} onChange={handleInputChange} />
      <TextBox
        rootClass=""
        label="Title"
        type="text"
        name="title"
        placeholder="Enter Title"
        value={cardConfig.title}
        onChange={handleInputChange}
      />
      <TextArea
        rootClass="w-full"
        label="Description"
        type="text"
        name="description"
        placeholder="Enter Description"
        value={cardConfig.description}
        onChange={handleInputChange}
      />
      <TextBox
          rootClass=""
          label="Payload"
          type="text"
          name="payload"
          placeholder="Enter Payload"
          value={cardConfig.payload}
          onChange={handleInputChange}
      />
      {/*<ActionButtons*/}
      {/*  dataField='buttons'*/}
      {/*  configs={cardConfig['buttons'] || []}*/}
      {/*  metadata={metadata}*/}
      {/*/>*/}
    </div>
  );
};

function Page({ name, onChangeData, savedSettings, metadata }) {
  const [formData, setFormData] = useState(savedSettings);
  const [cards, setCards] = useState([]);

  const notifyOnchangeData = data => {
    data.slot = data.slot || WORKFLOW_OPTION_SLOT;
    data.stateType = QUESTION_PROMPT
    onChangeData(data);
  }

  useEffect(() => {
    if (savedSettings && !isEqual(savedSettings.data, cards)) {
      setCards(savedSettings.data)
    }
  }, [savedSettings]);

  useEffect(() => {
    notifyOnchangeData(formData);
  }, [formData]);

  const handleChange = useCallback(event => {
    const { name, value } = event.target;

    setFormData(prevFormData => {
      // if (prevFormData[name] === value) return prevFormData;
      return {
        ...prevFormData,
        [name]: value
      };
    });
  }, []);

  const prevCards = useRef(cards);

  useEffect(() => {
    if (!isEqual(cards, prevCards.current)) {
      console.log('cards', cards)
      console.log('prevCards.current', prevCards.current)
      handleChange({
        target:{
          name: 'data',
          value: cards
        }
      })
      prevCards.current = cards;
    }
  }, [cards]);

  const prepareRawsData = useCallback((data = []) => {
    return data.map(header => ({ inputs: header }))
  }, [])

  const onDynamicRowChange = useCallback((rowsData) => {
    rowsData = rowsData.map(raw => raw.inputs)
    rowsData = rowsData.map(raw => {
      raw.thumbnail = raw.title;
      return raw;
    })
    setCards(rowsData)
  }, [])

  const memoizedDisplayTextOptions = useMemo(() => formData.displayTextOptions, [formData.displayTextOptions]);
  const memoizedRawData = useMemo(() => prepareRawsData(formData.data), [formData]);
  const renderRow = useCallback((cardConfig, handleInputChange, rawId) => {
    cardConfig.buttons = cardConfig?.buttons || [];
    return <Card key={rawId} rawId={rawId} cardConfig={cardConfig} handleInputChange={handleInputChange} metadata={metadata} />
  }, [])

  return (
    <div className="flex flex-col gap-2">
      <SettingTip text="Display a collection of visually organised cards, presenting information or options" />
      <TextBox
        label="Name"
        type="text"
        name="name"
        placeholder="State name"
        value={formData.name}
        onChange={handleChange}
      />
        <SlotSelector
            title="Variable"
            value={formData.slot}
            autoFillFromEntity={formData.autoFillFromEntity}
            slots={metadata.slots}
            onChange={handleChange}
        />
      <DisplayTexts
        parameterName={formData.slot}
        displayTextOptionsProps={memoizedDisplayTextOptions}
        responseModifyByAI={formData.responseModifyByAI}
        onChangeData={handleChange}
      />
      <DynamicRows
        title="Cards"
        className="pt-2"
        rowsData={memoizedRawData}
        onChange={onDynamicRowChange}
        showBottomDivider={false}
        defaultThumbnail="Card"
        rowsTemplate={renderRow}
      />
    </div>
  )
}

export default Page
