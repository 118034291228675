import { AiOutlineThunderbolt } from "react-icons/ai";
import { GrFlows } from "react-icons/gr";
import { BsStack } from "react-icons/bs";
import { LuBrainCircuit, LuWorkflow } from "react-icons/lu";
import { IoMdBook } from "react-icons/io";
import {
  MdAltRoute,
  MdConnectWithoutContact,
  MdOutlineAdsClick,
  MdOutlineCodeOff,
  MdOutlineSecurity,
  MdOutlineSupportAgent,
  MdPause,
  MdUploadFile
} from "react-icons/md";
import { PiWebhooksLogoThin } from "react-icons/pi";
import { TbFunction } from "react-icons/tb";


import { PiTextTBold } from "react-icons/pi";
import {
  RiImageAddLine,
  RiMessage2Line
} from "react-icons/ri";
import { TbArrowGuide, TbVariable } from "react-icons/tb";
import { HiOutlineSparkles } from "react-icons/hi2";

import { FaWandMagicSparkles as SparkIcon } from "react-icons/fa6";
import { TbSparkles as EditSparkIcon } from "react-icons/tb";


import {
  AI_TASK_ACTION_NODE_TYPE,
  AUTH_NODE_TYPE,
  BUTTONS_PROMPT_NODE_TYPE,
  CARDS_PROMPT_NODE_TYPE,
  CHOICE_NODE_TYPE,
  CODEHOOK_ACTION_NODE_TYPE,
  CODEHOOK_PROMPT_NODE_TYPE,
  DOC_SEARCH_ACTION_NODE_TYPE,
  IMAGE_PROMPT_NODE_TYPE, INTEGRATION_ACTION_NODE_TYPE,
  JOURNEY_ACTION_NODE_TYPE,
  LIVE_AGENT_ACTION_NODE_TYPE,
  MESSAGE_PROMPT_NODE_TYPE,
  PAUSE_ACTION_NODE_TYPE,
  QUESTION_PROMPT_NODE_TYPE,
  WEBHOOK_ACTION_NODE_TYPE
} from "../common/const/node-types";
import { WORKFLOW_OPTION_SLOT } from "../common/const/settings-const";
import {GEN_AI_TEXT_TO_WF, GEN_AI_TEXT_TO_WF_EDIT} from "../../../consts/gen-ai-consts";

export const toolboxFlowsTriggerButtons = [
  {
    id: "capture",
    title: "Capture",
    icon: MdUploadFile,
    nodes: [
      {
        id: QUESTION_PROMPT_NODE_TYPE,
        text: "Question",
        icon: PiTextTBold,
        defaultConfig: {
          name: "Question",
          slot: '',
          displayText: '',
          displayTextOptions: [],
          type: 'text',
          autoFillFromEntity: true,
          responseModifyByAI: false
        }
      },
      {
        id: BUTTONS_PROMPT_NODE_TYPE,
        text: "Button",
        icon: MdOutlineAdsClick,
        defaultConfig: {
          name: "Button",
          slot: '',
          displayText: '',
          displayTextOptions: [],
          data: [],
          type: 'buttons',
          autoFillFromEntity: true,
          responseModifyByAI: false,
        }
      },
      {
        id: CARDS_PROMPT_NODE_TYPE,
        text: "Cards",
        icon: BsStack,
        defaultConfig: {
          name: 'Cards',
          slot: WORKFLOW_OPTION_SLOT,
          displayText: '',
          displayTextOptions: [],
          data: [],
          type: 'cards',
          autoFillFromEntity: false,
          responseModifyByAI: false
        }
      },
      // {
      //   id: CODEHOOK_PROMPT_NODE_TYPE,
      //   text: "Codehook",
      //   icon: MdConnectWithoutContact,
      // },
    ],
  },
  {
    id: "response",
    title: "Response",
    icon: RiMessage2Line,
    nodes: [
      {
        id: MESSAGE_PROMPT_NODE_TYPE,
        text: "Text",
        icon: PiTextTBold,
        defaultConfig: {
          type: "text",
          name: "Message",
          displayText: '',
          displayTextOptions: [],
          responseModifyByAI: false
        }
      },
      {
        id: IMAGE_PROMPT_NODE_TYPE,
        text: "Image",
        icon: RiImageAddLine,
        defaultConfig: {
          type: "image",
          name: "Image",
          fileKey: ''
        }
      },
    ],
  },
  {
    id: "execute",
    title: "Execute",
    icon: AiOutlineThunderbolt,
    nodes: [
      {
        id: DOC_SEARCH_ACTION_NODE_TYPE,
        text: "AI Knowledge",
        icon: IoMdBook,
        defaultConfig: {
          type: 'docSearch',
          name: "AI Knowledge",
          systemPrompt: '',
          inputPrompt: '',
          slotToAssign: '',
          skipNotify: false,
        }
      },
      {
        id: AI_TASK_ACTION_NODE_TYPE,
        text: "AI Tasks",
        icon: LuBrainCircuit,
        defaultConfig: {
          type: 'aiTask',
          name: "AI Task",
          systemPrompt: '',
          assignToSlots: {
            executors: [{
              inputPrompt: "",
              slotToAssign: ""
            }]
          }
        }
      },
      {
        id: JOURNEY_ACTION_NODE_TYPE,
        text: "Workflow",
        icon: LuWorkflow,
        defaultConfig: {
          name: "Journey",
          type: 'journey',
          journeyId: ''
        }
      },
      {
        id: INTEGRATION_ACTION_NODE_TYPE,
        text: "Integration",
        icon: GrFlows,
        defaultConfig: {
          name: "Integration",
          type: "integrationWorkflow",
          runtimeInput: {},
          integrationConfig: {},
        }
      },
      {
        id: LIVE_AGENT_ACTION_NODE_TYPE,
        text: "Live Agent",
        icon: MdOutlineSupportAgent,
        defaultConfig:  {
          name: "Live Agent",
          type: 'liveagent',
          journeyId: ''
        }
      },
      {
        id: PAUSE_ACTION_NODE_TYPE,
        text: "Pause",
        icon: MdPause,
        defaultConfig: {
          name: "Pause",
          type: 'pause',
        }
      },
    ],
  },
  {
    id: "dev",
    title: "Dev",
    icon: MdOutlineCodeOff,
    nodes: [
      {
        id: WEBHOOK_ACTION_NODE_TYPE,
        text: "API",
        icon: PiWebhooksLogoThin,
        defaultConfig: {
          name: "API",
          type: 'webhook',
          slotToAssign: '',
          skipNotify: true,
          pauseAfterExecution: false,
          defaultInput: '{}',
          webhookConfig: {
            url: '',
            method: 'POST',
            parameters: [],
            requestFormData: [],
            requestBody: '{}',
            requestType: 'raw',
            headers: [],
            responseMapping: [],
          },
          requestMapper: {
            type: 'inline_script',
            inlineScript: ''
          },
          responseMapper: {
            type: 'inline_script',
            inlineScript: ''
          },
          responseModifyByAI: false
        }
      },
      {
        id: CODEHOOK_ACTION_NODE_TYPE,
        text: "Function",
        icon: TbFunction,
        defaultConfig: {
          name: "Function",
          type: 'codehook',
          codehookId: '',
          slotToAssign: '',
          autoFillFromEntity: true,
          skipNotify: false,
          defaultInput: '{}',
          responseModifyByAI: false
        }
      },
      {
        id: AUTH_NODE_TYPE,
        text: "Authenticate",
        icon: MdOutlineSecurity,
        defaultConfig: {
          name: "Authenticate",
          type: 'auth',
          authConnector: '',
          prompt: '',
          defaultInput: '{}'
        }
      },
    ],
  },

  {
    id: "logic",
    title: "Logic",
    icon: TbArrowGuide,
    nodes: [
      { id: CHOICE_NODE_TYPE, text: "Condition", icon: MdAltRoute },
      // { id: CHOICE_NODE_TYPE, text: "Fallback", icon: TiDropbox },
    ],
  },
  {
    id: "variable",
    title: "Variable",
    icon: TbVariable,
    event: "variable",
    nodes: [],
  },
  {
    id: "gen-ai-text-to-wf",
    title: "Generate",
    icon: HiOutlineSparkles,
    clickOnly: true,
    nodes: [
      {
        id: GEN_AI_TEXT_TO_WF,
        text: "Build from the scratch",
        icon: SparkIcon,
      },
      {
        id: GEN_AI_TEXT_TO_WF_EDIT,
        text: "Edit this Workflow",
        icon: EditSparkIcon,
      },
    ],
  },
];
