import {useEffect, useState } from 'react';
import PageContainer from "../../../../components/page-container";
import Button from '../../../../components/button/button';
import Message from "../../../../components/toast-message";
import Loader from "../../../../components/form-loader/form-loader";
import {useAppInfo, useQuery} from "../../../../helpers/hooks/common-hook";
import {
    dataSourceCreate,
    fileUpload,
    uploadUrlGet
} from "../../../../services/knowledge/ds-connector/file-connector";
import {InformationCircleIcon} from '@heroicons/react/solid';
import {useNavigate} from "react-router-dom";
import TextBox from "../../../../components/forms/text-box";
import TextArea from "../../../../components/forms/text-area";
import {validateActionName} from "../../../../helpers/utils/text-util";
import {NAME_VALIDATION_ERROR} from "../../../../consts/error-messages";
import SwitchComponent from "../../../../components/switch";

const textDataType = 'text';
const objectDataType = 'object';
const dataTypes = [textDataType, objectDataType]

export default function FileDSConnector({
                                            savedDataSet = {
                                                dataSourceId: '',
                                                dataType: 'text',
                                                idField: '',
                                                searchField: '',
                                                descriptionForModel: '',
                                                returnOriginal: false
                                            }
                                        }) {
    const {appId, intentLess } = useAppInfo();
    const query = useQuery();
    const dataType = query.get('dataType');
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        fileExt: '',
        metadata: savedDataSet
    });
    const [uploadUrl, setUploadUrl] = useState();
    const [uploadMetadata, setUploadMetadata] = useState();
    const [fileObj, setFileObj] = useState();

    const [error, setError] = useState({
        dataSourceId: false,
        dataType: false,
        idField: false,
        searchField: false,
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const [fileUrlGenState, setFileUrlGenState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const createUploadUrl = async () => {
        if (formData.metadata.dataSourceId && formData.metadata.dataType) {
            updateFileUrlGenState(true, false, false);
            try {
                const formDataRequest = {...formData};
                if (!Array.isArray(formDataRequest.metadata.searchableFields) && formDataRequest.metadata.searchableFields) {
                    formDataRequest.metadata.searchableFields = formDataRequest.metadata.searchableFields.split(",")
                }
                const {response, status} = await uploadUrlGet(appId, formDataRequest);
                if (status === 200) {
                    const {uploadUrl, metadata} = response;
                    setUploadUrl(uploadUrl);
                    setUploadMetadata(metadata);
                    updateFileUrlGenState(false, true, false);
                } else {
                    throw new Error(response)
                }
            } catch (err) {
                console.error("get-utl call failed cause", err);
                updateFileUrlGenState(false, false, true, 'Upload failed');
            }
        }
    }

    useEffect( () => {
        if (formData.metadata.dataSourceId && !validateActionName(formData.metadata.dataSourceId)) {
            setError({...error, dataSourceId: true})
            setUploadUrl(null)
        } else {
            setError({...error, dataSourceId: false})
            createUploadUrl()
        }
    }, [formData]);

    useEffect(() => {
        console.log("dataType", dataType)
        if (formData.metadata) {
            const metadata = formData.metadata;
            metadata.dataType = dataType;
            formData.metadata = metadata;
            setFormData({...formData});
        }
    }, [dataType]);

    useEffect( () => {
        if (formData.metadata.dataType) {
            setFileObj(null);
        }
    }, [formData.metadata.dataType]);

    useEffect(() => {
        if (formState.success) {
            setTimeout(() => {
                navigate(`/app/${appId}/knowledge-base/docs/data-sources`);
            }, 1500)
        }
    }, [formState.success]);

    const handleChange = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        const metadata = formData.metadata;
        if (targetName === 'dataSourceId') {
            metadata.dataSourceId = targetValue;
        } else if (targetName === 'dataType') {
            metadata.dataType = targetValue;
        } else if (targetName === 'idField') {
            metadata.idField = targetValue;
        } else if (targetName === 'searchField') {
            metadata.searchField = targetValue;
        } else if (targetName === 'searchableFields') {
            metadata.searchableFields = targetValue;
        }  else if (targetName === 'descriptionForModel') {
            metadata.descriptionForModel = targetValue;
        } else if (targetName === 'returnOriginal') {
            metadata.returnOriginal = targetValue;
        }

        formData.metadata = metadata;
        setFormData({...formData});
    };

    const handleSelectFile = async (event) => {
        console.log("event.target.files", event.target.files)
        const files = event.target.files;
        if (files.length > 0) {
            const file = files[0];
            formData.fileExt = file.name.split('.')[1];
            setFileObj(file)
            setFormData({...formData});
        }
    }

    const validateForm = () => {
        let valid = true;
        if (formData.metadata.dataSourceId === '') {
            error.dataSourceId = true;
            valid = false;
        } else {
            error.dataSourceId = false;
        }

        if (formData.metadata.dataType === '') {
            error.dataType = true;
            valid = false;
        } else {
            error.dataType = false;
        }

        if (formData.metadata.dataType === objectDataType) {
            if (formData.metadata.idField === '') {
                error.idField = true;
                valid = false;
            } else {
                error.idField = false;
            }

            if (formData.metadata.searchField === '') {
                error.searchField = true;
                valid = false;
            } else {
                error.searchField = false;
            }

            if (formData.metadata.descriptionForModel === '') {
                error.descriptionForModel = true;
                valid = false;
            } else {
                error.descriptionForModel = false;
            }
        }

        setError(Object.assign({}, error))
        return valid;
    }

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(Object.assign({}, {processing, success, failed, errorMessage}))
    }

    const updateFileUrlGenState = (processing, success, failed, errorMessage) => {
        setFileUrlGenState(Object.assign({}, {processing, success, failed, errorMessage}))
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        if (fileObj) {
            updateFormState(true, false, false)
            const response = await fileUpload(uploadUrl, fileObj);
            await dataSourceCreate(appId, uploadMetadata);
            if (response) {
                updateFormState(false, true, false)
            } else {
                updateFormState(false, false, true, 'Upload failed');
            }
        }
    };

    const handleOnCancel = () => {
        navigate(`/app/${appId}/integrations/docs/data-sources`);
    };

    const enableUpload = uploadUrl && (formData.metadata.dataSourceId && formData.metadata.dataType);
    console.log("enableUpload" , enableUpload)
    return (
        <PageContainer
            title="New Document"
            breadcrumbNav={[
                {name: 'Documents', href: `/app/${appId}/knowledge-base/docs/data-sources`},
                {name: 'Settings', href: '#'},
            ]}
            subtitle="Add document based knowledge into your agent"
            headerAction={<div className="flex flex-row items-center justify-end gap-2">
                <Button
                    variant="button"
                    color="default"
                    text="Reset"
                />
                <Button variant="button" text="Save Document"
                        onClick={handleSubmit}/>
            </div>}
        >
            <div className="pt-4 flex flex-col gap-4 pb-4" >

                {formState.processing && <div className="p-4"><Loader/></div>}

                {formState.success &&
                    <Message
                        text={`Successfully ${formData.id ? 'Updated' : 'Saved'} Uploaded Data`}
                        type="success"/>
                }

                {formState.failed && <Message text={formState.errorMessage} type="failure"/>}

                {fileUrlGenState.failed &&
                    <Message text={fileUrlGenState.errorMessage} type="failure"/>}

                <TextBox
                    rootClass="w-1/2"
                    label="Name"
                    type="dataSourceId"
                    name="dataSourceId"
                    id="dataSourceId"
                    value={formData.metadata.dataSourceId}
                    placeholder="Name of the data source"
                    onChange={handleChange}
                    error={error.dataSourceId}
                    errorMessage={NAME_VALIDATION_ERROR}
                    required
                />

                <TextArea
                    rootClass="w-1/2"
                    label="Description"
                    rows="6"
                    name="descriptionForModel"
                    id="descriptionForModel"
                    value={formData.metadata.descriptionForModel}
                    placeholder="Explain about your document"
                    onChange={handleChange}
                    error={error.descriptionForModel}
                />

                {dataType === 'csv' &&
                    <div className="flex flex-row justify-start w-1/2 py-2">
                        <SwitchComponent
                            title="Return Original"
                            subTitle="csv should contains question, answer columns for enabling this option"
                            enabled={formData.metadata.returnOriginal}
                            onChange={state => {
                                handleChange({ target: { name: 'returnOriginal' , value: state }})
                            }}
                        />
                    </div>
                }


                <div className="w-1/3">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                           htmlFor="fileContainer">
                        <span>Upload Document ( .{dataType} )</span>
                    </label>

                    {!enableUpload?
                        <input
                            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                            aria-describedby="user_avatar_help" id="fileContainer" type="file"
                            accept="application/zip"
                            disabled={true}
                        />
                        :
                        <input
                            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                            aria-describedby="user_avatar_help" id="fileContainer" type="file"
                            onChange={handleSelectFile}
                        />
                    }

                </div>

                {/*<div className="bg-white pt-6 flex flex-row items-center justify-end gap-2">*/}
                {/*    <div className="flex-1 flex flex-row items-center gap-2">*/}
                {/*        <InformationCircleIcon className="h-5 w-5 text-blue-600"*/}
                {/*                               aria-hidden="true"/>*/}
                {/*        <a className="text-sm text-blue-600 underline" href="#">Sample Data*/}
                {/*            source</a>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </PageContainer>
    )
}
