import { Route, Routes } from 'react-router-dom';
import { APP_BASE_PATH } from '../consts/route-consts';
import { useAppInfo } from "../helpers/hooks/common-hook";

import Page from "../components/page/page";
import AppListPage from "./agent-manage/agent-list";

import CodehooksListPage from "./codehook/codehook-list";
import CodehookSavePage from "./codehook/codehook-save/codehook-save-page";

import Subscription from "./subscription";

import NotificationList from "./notification/list";

import AppAccessKeyListPage from "./agent-manage/access-key/app-access-key-list/app-access-key-page";
import Index from "./agent-manage/agent-general-settings";
import AppLiveAgentPage from "./agent-manage/live-agent-settings";
import AppLLMSettingsPage from "./agent-manage/llm-settings";

import ActionJourneyStudioPage from "./action-journey-studio";
import AgentSettingsPage from "./agent-manage/agent-instruction";
import AgentActionsListPage from "./agent/agent-actions/action-list";
import AgentActionsSettingsPage from "./agent/agent-actions/action-settings";

// import IntegrationCustomize from "./channels/integration-customize/integration-web-page";
// import IntegrationWebPage from "./channels/integration-web/integration-web-page";
import IntegrationChannelPage from "./channels/integration-channel/integration-channel-page";
import IntegrationVoicePage from "./channels/integration-voice/integration-voice-page";
import IntegrationWebchatPage from "./channels/integration-webchat/integration-webchat-page";
import TwilioWebPage from "./channels/twilio-channel";

import WorkflowIntegrationList from "./integrations/workflow/workflow-list";

import AuthConnectorSave from "./integrations/auth-connectors/auth-connector-save";
import AuthConnectorsList from "./integrations/auth-connectors/auth-connectors-list";

import ContactUsPage from "./contact-us/contact-us-page";

import FileDSConnector from "./static-knowledge/data-source-connectors/file";
import TextDSConnector from "./static-knowledge/data-source-connectors/text";
import DataSourceManage from "./static-knowledge/data-source-manage";

import ShopifyConnectorsSave from "./integrations/shopify-connectors/shopify-connector-save";
import ShopifyConnectorsList from "./integrations/shopify-connectors/shopify-connectors-list";

import UserProfile from "./user-settings/user-profile";

import LiveChatDashboard from "./live-chat/live-chat-dashboard";
import FineTuneConfig from "./static-knowledge/fine-tune-config";

import AgentPreview from "./agent-preview";

import Analytics from "./analytics";
import ForgotPasswordPage from "./auth/internal-forgot-password";

const DashboardRouter = () => {
  const appInfo = useAppInfo();
  const intentLess = appInfo && appInfo.intentLess;
  return (
    <Routes>
      <Route
        path="/*"
        element={<Page title="Apps" content={<AppListPage />} />}
      />
      <Route
        path="/"
        element={<Page title="Apps" content={<AppListPage />} />}
      />

      <Route
        path={`${APP_BASE_PATH}/agent/settings`}
        element={
          <Page title="Agent Settings" content={<AgentSettingsPage />} />
        }
      />

      <Route
        path={`${APP_BASE_PATH}/agent/actions`}
        element={
          <Page title="Agent Actions List" content={<AgentActionsListPage />} />
        }
      />

      <Route
        path={`${APP_BASE_PATH}/agent/actions/settings`}
        element={
          <Page
            title="Agent Actions Settings"
            content={<AgentActionsSettingsPage />}
          />
        }
      />
      <Route
        path={`${APP_BASE_PATH}/agent/actions/settings/:actionId/*`}
        element={
          <Page
            title="Agent Actions Settings Update"
            content={<AgentActionsSettingsPage />}
          />
        }
      />
      <Route
        path={`${APP_BASE_PATH}/journey`}
        element={
          <Page title="Agent Workflow" content={<ActionJourneyStudioPage />} />
        }
      />
      <Route
        path={`${APP_BASE_PATH}/journey/:journeyId`}
        element={
          <Page title="Agent Workflow" content={<ActionJourneyStudioPage />} />
        }
      />

      <Route
        path={`${APP_BASE_PATH}/settings`}
        element={<Page title="App Settings" content={<Index />} />}
      />
      <Route
        path={`${APP_BASE_PATH}/access-keys`}
        element={
          <Page title="App AccessKey" content={<AppAccessKeyListPage />} />
        }
      />
      <Route
        path={`${APP_BASE_PATH}/live-agent`}
        element={<Page title="Live Chat" content={<AppLiveAgentPage />} />}
      />
      <Route
        path={`${APP_BASE_PATH}/llm`}
        element={<Page title="LLM Settings" content={<AppLLMSettingsPage />} />}
      />
      {/* <Route path={`${APP_BASE_PATH}/channels`}
                   element={<Page title="Integration Web" content={<IntegrationCustomize/>}/>}/> */}
      {/* <Route path={`${APP_BASE_PATH}/channels/customize`}
                   element={<Page title="Integration Web" content={<IntegrationCustomize/>}/>}/> */}
      <Route
        path={`${APP_BASE_PATH}/channels`}
        element={
          <Page title="Integration Web" content={<IntegrationWebchatPage />} />
        }
      />
      <Route
        path={`${APP_BASE_PATH}/channels/web`}
        element={
          <Page title="Integration Web" content={<IntegrationWebchatPage />} />
        }
      />
      <Route
        path={`${APP_BASE_PATH}/channels/voice`}
        element={
          <Page title="Integration Web" content={<IntegrationVoicePage />} />
        }
      />

      <Route
        path={`${APP_BASE_PATH}/channels/fb`}
        element={
          <Page
            title="Integration Web"
            content={
              <IntegrationChannelPage
                channel="facebook"
                titlePrefix="Facebook"
                subTitle="Messenger integration"
                callbackUrlLabel="Callback URL"
                accessKeyLabel="Access token"
                verifyTokenEnable={true}
                verifyTokenLabel="Verify token"
              />
            }
          />
        }
      />

      <Route
        path={`${APP_BASE_PATH}/channels/twilio`}
        element={<Page title="Twilio Web" content={<TwilioWebPage />} />}
      />

      <Route
        path={`${APP_BASE_PATH}/channels/whatsapp`}
        element={
          <Page
            title="Integration Whatsapp"
            content={
              <IntegrationChannelPage
                channel="whatsapp"
                titlePrefix="Whatsapp"
                subTitle="Whatsapp integration"
                callbackUrlLabel="Callback URL"
                accessKeyLabel="Access token"
                verifyTokenEnable={true}
                verifyTokenLabel="Verify token"
              />
            }
          />
        }
      />

      <Route
        path={`${APP_BASE_PATH}/channels/slack`}
        element={
          <Page
            title="Integration Slack"
            content={
              <IntegrationChannelPage
                channel="slack"
                titlePrefix="Slack"
                subTitle="Slack bot integration"
                callbackUrlLabel="Events Callback Url"
                accessKeyLabel="Bot User OAuth Token"
                verifyTokenEnable={false}
              />
            }
          />
        }
      />

      {/*<Route path={`${APP_BASE_PATH}/codehook/*`} element={<Page title="CodeHook" content={<CodehooksManagePage />} />} />*/}
      <Route
        path={`${APP_BASE_PATH}/contents`}
        element={<Page title="DataSource" content={<DataSourceManage />} />}
      />
      <Route
        path={`${APP_BASE_PATH}/functions/*`}
        element={<Page title="CodeHook" content={<CodehooksListPage />} />}
      />
      <Route
        path={`${APP_BASE_PATH}/functions/create`}
        element={
          <Page title="CodeHook Create" content={<CodehookSavePage />} />
        }
      />
      <Route
        path={`${APP_BASE_PATH}/functions/save/:codehookId`}
        element={<Page title="CodeHook Save" content={<CodehookSavePage />} />}
      />

      <Route
        path={`${APP_BASE_PATH}/knowledge-base/docs/data-sources`}
        element={<Page title="Knowledge" content={<DataSourceManage />} />}
      />
      <Route
        path={`${APP_BASE_PATH}/knowledge-base/docs/data-sources/connectors/file`}
        element={<Page title="File" content={<FileDSConnector />} />}
      />
      <Route
        path={`${APP_BASE_PATH}/knowledge-base/docs/data-sources/connectors/text`}
        element={<Page title="Text" content={<TextDSConnector />} />}
      />
      <Route
        path={`${APP_BASE_PATH}/knowledge-base/fine-tune`}
        element={<Page title="Knowledge" content={<FineTuneConfig />} />}
      />

     <Route
       path={`${APP_BASE_PATH}/integrations/workflow`}
       element={
         <Page title="Workflow" content={<WorkflowIntegrationList />} />
        }
     />

      <Route
        path={`${APP_BASE_PATH}/integrations/auth-connectors`}
        element={
          <Page title="AuthConnectors" content={<AuthConnectorsList />} />
        }
      />
      <Route
        path={`${APP_BASE_PATH}/integrations/auth-connectors/save`}
        element={
          <Page title="AuthConnectors" content={<AuthConnectorSave />} />
        }
      />
      <Route
        path={`${APP_BASE_PATH}/integrations/auth-connectors/save/:authConnId`}
        element={
          <Page title="AuthConnectors" content={<AuthConnectorSave />} />
        }
      />

      <Route
        path={`${APP_BASE_PATH}/integrations/shopify`}
        element={
          <Page title="AuthConnectors" content={<ShopifyConnectorsList />} />
        }
      />
      <Route
        path={`${APP_BASE_PATH}/integrations/shopify/save`}
        element={
          <Page title="AuthConnectors" content={<ShopifyConnectorsSave />} />
        }
      />
      <Route
        path={`${APP_BASE_PATH}/integrations/shopify/save/:id`}
        element={
          <Page title="AuthConnectors" content={<ShopifyConnectorsSave />} />
        }
      />

      <Route
        path={`${APP_BASE_PATH}/hitl`}
        element={
          <Page title="LiveChatDashboard" content={<LiveChatDashboard />} />
        }
      />

      <Route
            path={`${APP_BASE_PATH}/agent-preview`}
            element={
                <Page title="agent-preview" content={<AgentPreview />} />
            }
      />

      <Route
        path={`/notifications`}
        element={<Page title="Notifications" content={<NotificationList />} />}
      />

      <Route
        path={`${APP_BASE_PATH}/analytics`}
        element={<Page title="Analytics" content={<Analytics />} />}
      />

      <Route
        path={`/user-settings/profile`}
        element={<Page title="UserProfile" content={<UserProfile />} />}
      />
      <Route
        path={`/user-settings/subscription/*`}
        element={<Page title="Subscription" content={<Subscription />} />}
      />

      <Route path="/contact-us" element={<ContactUsPage />} />

      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
    </Routes>
  );
};

export default DashboardRouter;
