import {post, get} from "../../helpers/utils/http-client";
import {APPS_ENDPOINT} from "../../consts/api-endpoints";

export async function textGen(appId, data) {
    try {
        const { response, status } = await post(`${APPS_ENDPOINT}/${appId}/gen-ai/text-gen`,
            data);
        if (status !== 200) {
            throw Error('Unable to generate text')
        }
        return response;
    } catch (err) {
        console.error('textGen failed cause', err.message, err.code);
        return null;
    }
}

export async function textToBuild(appId, data) {
    try {
        console.log('textToBuild', data)
        const { response, status } = await post(`${APPS_ENDPOINT}/${appId}/gen-ai/text-to-build`,
            data);
        if (status !== 200) {
            throw Error('Unable to generate text')
        }
        return { response, status };
    } catch (err) {
        console.error('textGen failed cause', err.message, err.code);
        return {
            status: 500
        };
    }
}

export async function textToBuildStatus(appId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/gen-ai/text-to-build/status`);
        console.log('textToAgentBuildStatus')
        if (status !== 200) {
            throw Error('Unable to generate text')
        }
        return { response, status };
    } catch (err) {
        console.error('textGen failed cause', err.message, err.code);
        return {
            status: 500
        };
    }
}

export async function textToBuildStatusWithJourneyId(appId, journeyId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/gen-ai/text-to-build/${journeyId}/status`);
        console.log('textToAgentBuildStatus')
        if (status !== 200) {
            throw Error('Unable to generate text')
        }
        return { response, status };
    } catch (err) {
        console.error('textGen failed cause', err.message, err.code);
        return {
            status: 500
        };
    }
}
