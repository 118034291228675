import { useEffect, useState } from 'react';
import 'intro.js/introjs.css'; // Import the CSS
import TileList from '../../../components/tile-list';
import introJs from 'intro.js';
import { connect } from 'react-redux';
import { deleteApp, getAllApps } from '../../../services/app/app-service';
import PageContainer from "../../../components/page-container";
import AppSaveDialog from "../agent-save-modal";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import AppTemplateSelector from "../agent-template-selector";
import TemplateCardList from "../../../components/template-card-list";
import { HiOutlinePlus, HiOutlineServer } from 'react-icons/hi';
import { Button } from 'flowbite-react';

const mapsStateToProps = (state, ownProps) => {
  return {
    appCreateNotifyMessage: state.appCreateNotifyMessage
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}

function Index({ appCreateNotifyMessage }) {
  const [apps, setApps] = useState([]);
  const [openCreateApp, setOpenCreateApp] = useState(false);
  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    errorMessage: ''
  });

  const hasAssistants = apps.length > 0;

  const WelcomeMessage = () => (
    <div className="flex items-center py-8 justify-center">
      <div className="px-8 py-16 mt-6 text-left bg-white border rounded-md md:w-2/3 lg:w-1/2">
        <h2 className="text-2xl font-semibold text-center text-gray-800">Welcome to Bot Circuits!</h2>
        <p className="mt-2 text-center text-gray-600">Let's get you started with creating your first AI Application</p>
      </div>
    </div>
  );

  const [deletingData, setDeletingData] = useState(false);
  const [openDeleteConfirmDialog, setDeleteRevokeConfirmDialog] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [dataFetched, setDataFetched] = useState(false);


  async function fetchApps() {
    updateFormState(true, false, false);
    setApps(await getAllApps());
    updateFormState(false, false, false);
    setDataFetched(true);
  }
  const handleDelete = (appId) => {
    setDeletingData(appId)
  };

  async function deleteStore(status) {
    if (status) {
      updateFormState(true, false, false);
      await deleteApp(deletingData)
      updateFormState(false, false, false);
      await fetchApps();
      setDeletingData()
    }
    setDeleteRevokeConfirmDialog(false);
  }

  useEffect(() => {
    fetchApps();
  }, []);

  useEffect(() => {
    fetchApps();
  }, [appCreateNotifyMessage]);

  useEffect(() => {
    if (deletingData) {
      setDeleteRevokeConfirmDialog(true);
    }
  }, [deletingData]);

  const handleOpenCreateApp = () => {
    setSelectedTemplate(null);
    setOpenCreateApp(true);
  }

  const handleOnCompletionCreateApp = () => {
    setOpenCreateApp(false);
  }

  const updateFormState = (processing, success, failed, errorMessage) => {
    setFormState(Object.assign({}, { processing, success, failed, errorMessage }))
  }

  const processDataList = apps.map(dt => {
    return {
      record: dt
    }
  })

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [openTemplateSelector, setOpenTemplateSelector] = useState(false);
  const handleOnOpenTemplateSelector = () => {
    setOpenTemplateSelector(true);
  }

  const handleOnCompleteTemplateSelector = template => {
    setOpenTemplateSelector(false);
    if (template) {
      setSelectedTemplate({ ...template })
    }
  }

  useEffect(() => {
    if (dataFetched && !hasAssistants) {
      const intro = introJs();
      intro.setOptions({
        steps: [
          {
            element: '#new-assistant-btn',
            intro: 'Click here to create your first Assistant.',
            position: 'bottom'
          },
          {
            element: '#assistant-library-btn',
            intro: 'Or select an Assistant from the library.',
            position: 'bottom'
          }
        ],
        exitOnOverlayClick: false,
        showBullets: false
      });
      intro.start();
    }
  }, [hasAssistants, dataFetched]);

  function showBody() {
    if (dataFetched && hasAssistants) {
      return <TileList tiles={processDataList} onDelete={handleDelete} onClickCreate={handleOpenCreateApp} />

    } else if (dataFetched && !hasAssistants) {
      return (
      <div>
        <WelcomeMessage />
      </div>
    )}
  }

  useEffect(() => {
    if (selectedTemplate) {
      setOpenCreateApp(true);
    }
  }, [selectedTemplate]);

  return (
    <PageContainer title="My Applications"
      tooltip=""
      headerAction={
        <div className="flex flex-row items-center gap-4">
          <Button
            className="text-indigo-700 border border-indigo-500 hover:text-indigo-800 transition-colors"
            color="light"
            onClick={handleOnOpenTemplateSelector}
            id="assistant-library-btn"
          >
            <HiOutlineServer className="mr-2" size={20} />
            Start with a template
          </Button>
          <Button
            color="primary"
            className="bg-indigo-700 text-white hover:bg-indigo-800 transition-colors"
            onClick={handleOpenCreateApp}
            id="new-assistant-btn"
          >
            <HiOutlinePlus className="mr-2" size={20} />
            New Application
          </Button>
        </div>
      }
    >

      {openCreateApp && (
        <AppSaveDialog
          onCompletion={handleOnCompletionCreateApp}
          onCancel={handleOnCompletionCreateApp}
          selectedTemplateProp={selectedTemplate}
        />
      )}

      {openDeleteConfirmDialog && (
        <ConfirmDialog
          appId={deletingData}
          open={openDeleteConfirmDialog}
          onCompletion={deleteStore}
          title="Delete Confirmation"
          message="Are you sure you want to delete this Application?"
        />
      )}

      {openTemplateSelector && (
        <AppTemplateSelector onCompletion={handleOnCompleteTemplateSelector} />
      )}
      {formState.processing && (
        <TemplateCardList />
      )}
      {showBody()}
    </PageContainer>

  )
}

export default connect(mapsStateToProps, mapDispatchToProps)(Index)
