import { useEffect, useState, memo } from 'react';
import Button from "../../../components/button/button";
import TableReloadButton from "../../../components/button/table-reload-button";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import PageContainerV2 from '../../../components/page-container/page-container-v2';
import Table from "../../../components/table";
import { useAppInfo } from '../../../helpers/hooks/common-hook';
import { deleteDataSources, listDataSources } from '../../../services/knowledge';
import SectionHeading from '../../channels/_components/section-heading';
import DataSourceConnectorFileModel from "../data-source-connectors/file-modal";

const statusMap = {
  'preparing': { color: 'text-sky-700', title: 'Preparing' },
  'ready': { color: 'text-green-500', title: 'Ready' },
  'error': { color: 'text-red-600', title: 'Issue' }
};

const dbTypeMap = {
  'postgresql': 'PostgreSQL',
  'mysql': 'MySQL'
};

function ModelStatusComp({ modelStatus }) {
  return (
    <div className={statusMap[modelStatus].color}>
      {statusMap[modelStatus].title}
    </div>
  );
};

export default function DataSourceManage() {
  const { appId, version } = useAppInfo();
  const [items, setItems] = useState([]);

  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    errorMessage: ''
  });

  const [openConnSelectorDialog, setOpenConnSelectorDialog] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [deletingKnowledge, setDeletingKnowledge] = useState({});
  const [openDeleteConfirmDialog, setDeleteRevokeConfirmDialog] = useState(false);

  async function fetchConfigs() {
    updateFormState(true, false, false);
    try {
      const { response, status } = await listDataSources(appId, version);
      if (status === 200) {
        setItems(response.data);
        updateFormState(false, false, false);
      } else {
        throw new Error(response)
      }
    } catch (e) {
      updateFormState(false, true, false);
    }
  };

  useEffect(() => {
    fetchConfigs();
  }, []);

  useEffect(() => {
    if (deletingKnowledge && deletingKnowledge.id) {
      setDeleteRevokeConfirmDialog(true);
    }
  }, [deletingKnowledge]);

  const updateFormState = (processing, success, failed, errorMessage) => {
    setFormState(Object.assign({}, { processing, success, failed, errorMessage }));
  };

  const handleOpenConnSelector = () => {
    setOpenConnSelectorDialog(true);
  };

  const handleOnCancelConnectorSelect = (state) => {
    setOpenConnSelectorDialog(false);
    if (state) {
      fetchConfigs();
    }
  };

  const handleSelectDelete = (id, returnOriginal) => {
    setDeletingKnowledge({ id, returnOriginal });
  };

  const handleConfirmDelete = async confirmed => {
    setDeleteRevokeConfirmDialog(false);
    if (confirmed) {
      updateFormState(true, false, false);
      await deleteDataSources(appId, deletingKnowledge.id, deletingKnowledge.returnOriginal)
      setDeletingKnowledge({});
      await fetchConfigs();
      updateFormState(false, true, false);
    }
  };

  /**
   * id: resp.dataSourceId,
   *                     title: resp.dataSourceId,
   *                     subTitle: resp.dataSourceConnector,
   *                     description: parseAndFormat(resp.created_at, DATE_STR_FORMAT),
   * @type {{columnsData: [{data: *, isIdField: boolean},{data: *|string},{isUpdatedField: boolean, data: *},{options: [{name: string, link: string},{onClick: function(): void, name: string}]}], record: *}[]}
   */

  const processDataList = items.map(item => {
    return {
      record: item,
      columnsData: [
        { data: item.dataSourceId, isIdField: true },
        { data: <span className="text-transform: capitalize;">{`${item.dataSourceType} | ${item.dataSourceType === 'file'? item.fileType: item.dbType}`}</span>},
        { data: <ModelStatusComp modelStatus={item.modelStatus} /> },
        { data: item.modifiedAt, isUpdatedField: true },
        {
          options: [
            {
              name: 'Delete Document',
              onClick: () => handleSelectDelete(item.dataSourceId, item.returnOriginal)
            }
          ]
        },
      ]
    }
  });

  const handleRowSelect = (record) => {
    if (selectedItems?.length && selectedItems.some(item => item.dataSourceId === record.dataSourceId)) {
      setSelectedItems((preItems) => preItems.filter(prItem => prItem.dataSourceId !== record.dataSourceId));
    } else {
      setSelectedItems((preItems) => ([ ...preItems, record ]));
    }
  };

  const handleSelectAllClick = () => {
    if (selectedItems?.length === processDataList?.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(processDataList?.map(dataItem => dataItem.record));
    }
  }

  const deleteMultiple = () => {
    console.log('delete', selectedItems);
  };

  const DeleteSelectedButton = memo(({ selectedItems }) => {
    if (selectedItems?.length) {
      return (
        <div className="flex justify-end mt-8 -mb-12">
          <Button
            text={`Delete Selected (${selectedItems?.length})`}
            color="default"
            className="border border-red-600 bg-white text-red-800 hover:bg-slate-50"
            onClick={deleteMultiple}
          />
        </div>
      );
    }
    return null;
  });

  return (
    <PageContainerV2
      title="Data Sources"
      subtitle="Data Sources"
      pageType="contents"
      pageSidebar={true}
    >
      <div className="flex h-full flex-1 flex-grow">
        <div className="flex flex-col flex-1">
          <SectionHeading title="Knowledge">
            <div className="flex flex-row items-center gap-4">
              <TableReloadButton onClick={() => fetchConfigs()} />
              <Button onClick={handleOpenConnSelector} text="New Data Source" />
            </div>
          </SectionHeading>
          <div className="flex flex-col space-y-8 px-5 w-full">
            {(openConnSelectorDialog) &&
              <DataSourceConnectorFileModel onCancel={handleOnCancelConnectorSelect} />
            }
            {openDeleteConfirmDialog &&
              <ConfirmDialog
                open={openDeleteConfirmDialog}
                appId={appId}
                onCompletion={handleConfirmDelete}
                message="Are you sure. Do you want to delete this Data Source ?"
              />
            }
            <DeleteSelectedButton selectedItems={selectedItems} />
            <Table
              headers={['Name', 'Source Type', 'Type', 'Status']}
              items={processDataList}
              selectedItems={selectedItems}
              onRowSelect={handleRowSelect}
              onSelectAllClick={handleSelectAllClick}
              dataLoadingState={formState}
              itemsNotFoundMessage="You don't have any Data Sources"
              multiSelect
            />
          </div>
        </div>
      </div>
    </PageContainerV2>
  )
}
