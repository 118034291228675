import React, {useEffect, useState} from "react";
import {StmStateDto} from "../../common/dto/stm-state-dto";
import {ACTION_PROMPT} from "../../common/const/settings-type";
import SlotSelector from "../../common/components/slot-selector";
import { listCodehook } from "../../../../services/model/model-config-service";
import {useAppInfo} from "../../../../helpers/hooks/common-hook";
import { Switch } from '@headlessui/react'
import CodeEditorDialog from "../../common/components/code-editor-dialog";
import Button from "../../../../components/button/button";
import Loader from "../../../../components/form-loader/form-loader";
import TextBox from "../../../../components/forms/text-box";
import SelectInput from "../../../../components/forms/select";
import SettingTip from "../../common/components/setting-tip";
import SentToUserSwitch from "../../common/components/send-to-user-switch";
import Editor from "@monaco-editor/react";
import ResponseAIModify from "../../common/components/response-ai-modify-select";

function Page({name, onChangeData, savedSettings, metadata={}}) {
    const { appId } = useAppInfo();
    const [formData, setFormData] = useState(savedSettings);

    const [codehookList, setCodehookList] = useState([]);
    const [codehookListLoadingState, setCodehookListLoadingState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: '',
        errorMessage: ''
    });

    const updateCodehookListLoadingState = ({processing, success, failed, message, errorMessage}) => {
        setCodehookListLoadingState({processing, success, failed, message, errorMessage})
    }

    const [openCodeEditor, setOpenCodeEditor] = useState();

    const fetchCodeHookList = async ()=> {
        updateCodehookListLoadingState({
            processing: true
        });
        const { data } = await listCodehook(appId)
        setCodehookList(data);
        updateCodehookListLoadingState({
            processing: false
        });
    }

    useEffect( () => {
        fetchCodeHookList()
    }, []);

    useEffect(() => {
        formData.stateType = ACTION_PROMPT
        onChangeData(formData);
    }, [formData]);

    useEffect(() => {
        if (formData.skipNotify) {
            const newFormData = { ...formData }
            newFormData.responseModifyByAI = false
            setFormData(newFormData);
        }
    }, [formData.skipNotify]);

    const handleChange = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        const newFormData = { ...formData }
        newFormData[targetName] = targetValue
        setFormData(newFormData);
    };

    const handleResponseModifyByAI = event => {
        if (!formData.skipNotify) {
            handleChange(event)
        }
    };

    const onClickOpenCodeEditor = () => {
        setOpenCodeEditor(true)
    }

    const onCodeEditComplete = (code) => {
        setOpenCodeEditor(false)
        formData.defaultInput = code;
        setFormData({...formData});
    }

    const handleRefreshCodeList = () => {
        fetchCodeHookList();
    }

    return (
        <div className="flex flex-col gap-2">

            <SettingTip text="The CodeHook allows you executing custom code that involve retrieving data from an external database or sending data to another source"/>

            {codehookListLoadingState.processing &&
                <Loader/>
            }

            <TextBox
                label="Name"
                type="text"
                name="name"
                placeholder="State name"
                value={formData.name}
                onChange={handleChange}
            />

            {/*<SlotSelector value={formData.slotToAssign} autoFillFromEntity={formData.autoFillFromEntity} slots={metadata.slots} onChange={handleChange} />*/}

            <SentToUserSwitch formData={formData} setFormData={setFormData} skipNotify={formData.skipNotify}/>

            <div className="pt-4">
                <ResponseAIModify
                    responseModifyByAI={formData.responseModifyByAI}
                    onChange={handleResponseModifyByAI}/>
            </div>

            <SelectInput
                rootClass="pt-4"
                label="Function"
                name="codehookId"
                autoComplete="codehookId"
                value={formData.codehookId}
                onChange={handleChange}
                optionsComp={<>
                    <option value="">Select Codehook</option>
                    {}
                    {codehookList.map((cd, si)=>(
                        <option key={si} value={cd.codehookId}>{cd.codehookId}</option>
                    ))}
                </>}
            />

            <div className="flex flex-row justify-end items-center">
                <div className="flex flex-row justify-between pr-2">
                    <svg onClick={handleRefreshCodeList} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-900">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                </div>
                <a href={`/app/${appId}/functions/create`} target="_blank" className="text-sm text-blue-700 font-bold underline">Create Function</a>
            </div>


            <div className="mt-2 flex flex-col gap-2">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                    Default Input
                </label>
                <Editor
                    height="10vh"
                    defaultLanguage='json'
                    value={formData.defaultInput || '{}'}
                    theme="vs-dark"
                    onChange={value=>{
                        const newFormData = {...formData};
                        newFormData.defaultInput = value
                        setFormData(newFormData);
                    }}
                />
            </div>

        </div>
    )
}

export default Page
