import {useEffect, useRef, useState} from 'react';
import PageContainer from "../../../components/page-container";
import Messenger from "./../messenger";
import SessionsList from "../sessions-list";
import {getDefaultAccessKeys} from "../../../services/app/app-access-key-service";
import {useAppContext} from "../../../helpers/hooks/common-hook";
import {APP_NAVIGATION} from "../../agent-manage/consts/navigations";

export default function LiveChatDashboard() {

    const { appInfo } = useAppContext();
    const [accessKey, setAccessKey] = useState();
    const [selectedSession, setSelectedSession] = useState();

    const fetchDefaultAccessKeys = async () => {
        if (appInfo.appId) {
            const response = await getDefaultAccessKeys(appInfo.appId);
            setAccessKey(response.accessKey)
        }
    }

    useEffect( () => {
        fetchDefaultAccessKeys();
    }, [appInfo]);

    const onSelectSession = (session) => {
        console.log("onSelectSession", session)
        setSelectedSession(session);
    }

    return (
        <PageContainer title="Conversations" subtitle="Allows human intervention in the bot's conversations.">
            <div className="grid grid-cols-4">
                <SessionsList onSelectSession={onSelectSession}/>
                {(selectedSession && accessKey) &&
                    <Messenger
                        session={selectedSession}
                        accessKey={accessKey}
                        chatBotSetting={{
                            header: 'BotCircuits',
                            welcomeMessage: 'Hello',
                            inputPlaceHolder: 'Ask any thing...'
                        }}
                    />
                }

                {(!selectedSession || !accessKey) &&
                    <div className="col-span-3 max-height-wrapper overflow-y-auto bg-gray-200 px-4 py-4">

                        <div className="border-l-4 border-blue-400 bg-blue-50 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5 text-blue-400">
                                        <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm text-blue-700">
                                        Please select session to start live chat
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                }

            </div>
        </PageContainer>

    )
}
