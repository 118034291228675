import { post } from "../../../helpers/utils/http-client";
import {APPS_ENDPOINT} from '../../../consts/api-endpoints';

export async function uploadUrlGet(appId, request) {
    return await post(`${APPS_ENDPOINT}/${appId}/knowledge/data-sources/file/url`, request);
}

export async function dataSourceCreate(appId, metadata) {
    return await post(`${APPS_ENDPOINT}/${appId}/knowledge/data-sources`, metadata);
}

export async function fileUpload(preSignedUrl, file) {
    try {
        const headers = new Headers();
        headers.append("Content-Type", "application/zip");
        const requestOptions = {
            method: 'PUT',
            body: file,
            redirect: 'follow',
            headers,
        };
        return await fetch(preSignedUrl, requestOptions)
    } catch (err) {
        console.debug('file upload failed cause', err);
        return null;
    }
}
